import React, { useEffect, useState } from "react";
import AboutUs from "../../components/Replica/AboutUs";
import ReplicaBanners from "../../components/Replica/Banner";
import ChooseUs from "../../components/Replica/ChooseUs";
import ContactUs from "../../components/Replica/ContactUs";
import CustomCount from "../../components/Replica/CustomCounts";
import Enquiry from "../../components/Replica/Enquiry";
import Features from "../../components/Replica/Features";
import Services from "../../components/Replica/Services";
import { ApiHook } from "../../hooks/apiHook";
import { useNavigate, useLocation } from "react-router";
import { useTranslation } from "react-i18next";
import { format } from "date-fns";
import { Link, NavLink, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import TermsAndConditionReplicaModal from "../../components/Common/modals/ReplicaTerms";
import PolicyReplica from "../../components/Common/modals/ReplicaPolicy";

const MainReplica = () => {
    const params = useParams();
    if (params.username) {
        localStorage.setItem("referralId", params.username);
    }
    const [showTerms, setShowTerms] = useState(false);
    const [showPolicy, setShowPolicy] = useState(false);
    const [fillPercentage, setFillPercentage] = useState(0);
    const [isSticky, setIsSticky] = useState(false);
    const [showMobileMenu, setShowMobileMenu] = useState(false);
    const navigate = useNavigate();
    const companyDetails = useSelector(
        (state) => state.replica?.companyDetails
    );
    const location = useLocation();
    const { t } = useTranslation();
    const terms = useSelector((state) => state?.replica?.termsAndPolicy?.terms);
    const policy = useSelector(
        (state) => state?.replica?.termsAndPolicy?.policy
    );
    const handleScroll = () => {
        const scrollTop = document.documentElement.scrollTop;
        const scrollDown = window.scrollY;
        const windowHeight = window.innerHeight;
        const documentHeight = document.documentElement.scrollHeight;

        if (scrollDown < 135) {
            setIsSticky(false);
        } else {
            setIsSticky(true);
        }

        const scrollPercentage =
            (scrollTop / (documentHeight - windowHeight)) * 100;

        const newFillPercentage = Math.min(Math.max(scrollPercentage, 0), 100);

        setFillPercentage(newFillPercentage);
    };
    const handleNavLink = () => {
        setShowMobileMenu(false);
        // const hash = localStorage.getItem("hashKey");
        const referraiId = localStorage.getItem("referralId");
        navigate(`/replica/${referraiId}`);
    };

    const handleScrollToTop = () => {
        window.scrollTo({ top: 0, behavior: "smooth" });
    };

    useEffect(() => {
        window.addEventListener("scroll", handleScroll);
        return () => {
            window.removeEventListener("scroll", handleScroll);
        };
    }, []);

    const replica = ApiHook.CallReplicaHome();
    if (replica.isLoading) {
        return (
            <div className="theme-loader">
                <div className="spinner">
                    <div className="spinner-bounce one"></div>
                    <div className="spinner-bounce two"></div>
                    <div className="spinner-bounce three"></div>
                </div>
            </div>
        );
    }

    // const registerLink = useSelector((state) => state?.replica?.registerLink);
    const favicon = document.getElementById("dynamic-favicon");
    if (favicon) {
        favicon.href = companyDetails?.favicon;
    }
    const currentDate = new Date();
    const currentYear = format(currentDate, "yyyy");

    return (
        <div className="pageReplica" id="pageReplica">
            {
                <>
                    <div className="top__bar-four">
                        <div className="custom__container">
                            <div className="row">
                                <div className="col-lg-8">
                                    <div className="top__bar-four-left lg-t-center">
                                        <ul>
                                            <li>
                                                <NavLink
                                                    to=""
                                                    target=""
                                                    style={{
                                                        pointerEvents: "none",
                                                    }}
                                                >
                                                    <i className="fas fa-map-marker-alt"></i>
                                                    {`${t("location")} :
            ${companyDetails?.address ?? ""}`}
                                                </NavLink>
                                            </li>
                                            <li>
                                                <a href="mailto:info.me@gmail.com">
                                                    <i className="fas fa-envelope"></i>
                                                    {`${t("email")} :
            ${companyDetails?.email ?? ""}`}
                                                </a>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                                <div className="col-lg-4">
                                    <div className="top__bar-four-right"></div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div
                        className={`header__sticky ${
                            isSticky ? "header__sticky-sticky-menu" : ""
                        }`}
                    >
                        <div className="container custom__container">
                            <div className="header__area-menubar p-relative">
                                <div className="header__area-menubar-left">
                                    <div className="header__area-menubar-left-logo">
                                        <NavLink to="" onClick={handleNavLink}>
                                            <img
                                                className="dark-n"
                                                src={
                                                    companyDetails?.logo ??
                                                    "/img/logo.png"
                                                }
                                                alt="Logo"
                                            />
                                            <img
                                                className="light-n"
                                                src={
                                                    companyDetails?.logo ??
                                                    "/img/logo-1.png"
                                                }
                                                alt="Logo"
                                            />
                                        </NavLink>
                                    </div>
                                </div>
                                <div className="header__area-menubar-center">
                                    <div className="header__area-menubar-center-menu four menu-responsive">
                                        <ul
                                            id="mobilemenu"
                                            className={`${
                                                showMobileMenu
                                                    ? "menu_open"
                                                    : ""
                                            }`}
                                        >
                                            <li>
                                                <a
                                                    href="#home"
                                                    onClick={handleNavLink}
                                                >
                                                    {t("home")}
                                                </a>
                                            </li>

                                            <li>
                                                <a
                                                    href="#aboutus"
                                                    onClick={handleNavLink}
                                                >
                                                    {t("about_us")}
                                                </a>
                                            </li>
                                            <li>
                                                <a
                                                    href="#services"
                                                    onClick={handleNavLink}
                                                >
                                                    {t("services")}
                                                </a>
                                            </li>
                                            <li>
                                                <a
                                                    href="#contact"
                                                    onClick={handleNavLink}
                                                >
                                                    {t("contact_us")}
                                                </a>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                                <div className="header__area-menubar-right">
                                    <div className="header__area-menubar-right-responsive-menu menu__bar two">
                                        <i
                                            className="flaticon-dots-menu"
                                            onClick={() =>
                                                setShowMobileMenu(true)
                                            }
                                        ></i>
                                    </div>
                                    <div className="header__area-menubar-right-contact">
                                        <div className="header__area-menubar-right-contact-icon">
                                            <i className="fal fa-envelope-open-text"></i>
                                        </div>
                                        <div className="header__area-menubar-right-contact-info">
                                            <span>{t("message")}</span>
                                            <h6>
                                                <a>{companyDetails?.email}</a>
                                            </h6>
                                        </div>
                                    </div>
                                    <div className="header__area-menubar-right-btn four">
                                        {location.pathname !==
                                            `/replica-register` && (
                                            <Link
                                                className="btn-one"
                                                to={`/replica-register/${params.username}`}
                                            >
                                                {t("register")}{" "}
                                            </Link>
                                        )}
                                    </div>
                                </div>
                            </div>
                            <div
                                className={`menu__bar-popup four ${
                                    showMobileMenu ? "show" : ""
                                }`}
                            >
                                <div className="menu__bar-popup-close">
                                    <i
                                        className="fal fa-times"
                                        onClick={() => setShowMobileMenu(false)}
                                    ></i>
                                </div>
                                <div className="menu__bar-popup-left">
                                    <div className="menu__bar-popup-left-logo">
                                        <a href="index.html">
                                            <img
                                                src="/img/logo.png"
                                                alt="logo"
                                            />
                                        </a>
                                        <div className="responsive-menu"></div>
                                    </div>
                                    <div className="menu__bar-popup-left-social"></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </>
            }
            <ReplicaBanners
                data={replica?.data?.data?.replicaHome?.replicaBanners}
            />
            <Features data={replica?.data?.data?.replicaHome?.features} />
            <AboutUs data={replica?.data?.data?.replicaHome?.aboutUs} />
            <Services data={replica?.data?.data?.replicaHome?.services} />
            <CustomCount />
            <ChooseUs data={replica?.data?.data?.replicaHome?.chooseUs} />
            <Enquiry />
            <ContactUs companyDetails={replica?.data?.data?.companyDetails} />
            {
                <>
                    <div
                        className="footer__two"
                        style={{
                            backgroundImage: "url(/img/shape/footer-bg.png)",
                        }}
                    >
                        <div className="container">
                            <div className="row">
                                <div className="col-xl-3 col-lg-3 col-sm-12">
                                    <div className="footer__two-widget">
                                        <h4>{t("main_pages")}</h4>
                                        <div className="footer__area-widget-menu four">
                                            <ul>
                                                <li>
                                                    <a
                                                        href="#home"
                                                        onClick={handleNavLink}
                                                    >
                                                        {t("home")}
                                                    </a>
                                                </li>

                                                <li>
                                                    <a
                                                        href="#aboutus"
                                                        onClick={handleNavLink}
                                                    >
                                                        {t("about_us")}
                                                    </a>
                                                </li>
                                                <li>
                                                    <a
                                                        href="#services"
                                                        onClick={handleNavLink}
                                                    >
                                                        {t("services")}
                                                    </a>
                                                </li>
                                                <li>
                                                    <a
                                                        href="#contact"
                                                        onClick={handleNavLink}
                                                    >
                                                        {t("contact_us")}
                                                    </a>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-xl-3 col-lg-3 col-sm-12">
                                    <div className="footer__two-widget">
                                        <h4>{t("quick_links")}</h4>
                                        <div className="footer__area-widget-menu four">
                                            <ul>
                                                <li>
                                                    <Link
                                                        onClick={() =>
                                                            setShowTerms(true)
                                                        }
                                                    >
                                                        {t(
                                                            "terms_and_condition"
                                                        )}
                                                    </Link>
                                                </li>
                                                <li>
                                                    <Link
                                                        onClick={() =>
                                                            setShowPolicy(true)
                                                        }
                                                    >
                                                        {t("privacy_policy")}
                                                    </Link>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="copyright__one">
                            <div className="container">
                                <div className="row">
                                    <div className="col-xl-12">
                                        <p>
                                            Copyright {currentYear}{" "}
                                            <a
                                                href="#home"
                                                onClick={() => handleNavLink}
                                            >
                                                {companyDetails?.name}
                                            </a>{" "}
                                            - All Rights Reserved
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div
                        className={`scroll-up scroll-four ${
                            fillPercentage > 0 ? "active-scroll" : ""
                        }`}
                        onClick={handleScrollToTop}
                    >
                        <svg
                            className="scroll-circle svg-content"
                            width="100%"
                            height="100%"
                            viewBox="-1 -1 102 102"
                        >
                            <path
                                d="M50,1 a49,49 0 0,1 0,98 a49,49 0 0,1 0,-98"
                                strokeDasharray="307.919"
                                strokeDashoffset={
                                    (307.919 * (100 - fillPercentage)) / 100
                                }
                            />
                        </svg>
                    </div>
                </>
            }
            <TermsAndConditionReplicaModal
                show={showTerms}
                setShow={setShowTerms}
                data={terms}
            />
            <PolicyReplica
                show={showPolicy}
                setShow={setShowPolicy}
                data={policy}
            />
        </div>
    );
};

export default MainReplica;
