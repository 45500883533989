import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { ApiHook } from "../../hooks/apiHook";
import TicketForm from "../../components/Support/TicketForm";
import CommonTableComponent from "../../components/Common/CommonTableComponent";
import moment from "moment";
import TablePagination from "../../components/Common/table/TablePagination";


const MainSupport = () => {
  
  const START_PAGE = 1;
  
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(10);

  const toNextPage = () => {
    if (currentPage < totalPages) {
        setCurrentPage(currentPage + 1);
    }
  };
  const toLastPage = () => {
    setCurrentPage(totalPages);
  };
  const toPreviousPage = () => {
    if (currentPage > START_PAGE) {
        setCurrentPage(currentPage - 1);
    }
  };
  const toFirstPage = () => {
    setCurrentPage(START_PAGE);
  };
  

  //------------------------------------------- API -----------------------------------------

  const partials = ApiHook.CallTicketPartials(setTotalPages, currentPage, itemsPerPage);

  const tickets = ApiHook.CallTickets(
    currentPage,
    itemsPerPage,
    setTotalPages,
  );

  const statusColorLookUp = {
    "pending" : {backgroundColor: "#c7e6d3", color: "#66bc7e"},
    "approved" : {backgroundColor: "#E4F0F2", color: "#2E8CA5"},
    "rejected" : {backgroundColor: "#FEE4D1", color: "#9B5731"},
  }

  const headers = [
    { label: "DATE", filter: "createdAt", filterable: false, render: (el) => <span>{moment(el).format("YYYY/MM/DD")}</span> },
    { label: "IBE #", filter: "ibe", filterable: true, render: (el) => <span>{el}</span> },
    { label: "IBE NAME", filter: "ibeName", filterable: true, render: (el) => <span>{el}</span> },
    { label: "MOBILE", filter: "ibeMobile", filterable: true, render: (el) => <span>{el || "NA"}</span> },
    { label: "EMAIL", filter: "ibeEmail", filterable: true, render: (el) => <span>{el}</span> },
    { label: "CITY", filter: "ibeCity", filterable: true, render: (el) => <span>{el || "NA"}</span> },
    { label: "STATE", filter: "ibeState", filterable: true, render: (el) => <span>{el}</span> },
    { label: "COUNTRY", filter: "ibeCountry", filterable: true, render: (el) => <span>{el}</span> },
    { label: "TICKET ID", filter: "trackId", filterable: false, render: (el) => <span>{el}</span> },
    { label: "SUBJECT", filter: "subject", filterable: false, render: (el) => <span>{el}</span> },
    { label: "STATUS", filter: "status", filterable: false, render: (el) => { 
      let color = statusColorLookUp[el]?.color;
      let backgroundColor = statusColorLookUp[el]?.backgroundColor;

      return <div style={{ backgroundColor, color, width: "110px", padding: "3px 25px 3px 25px", borderRadius: "10px", fontWeight: "bold" }}>{el}</div>
    }},
    { label: "CATEGORY", filter: "category", filterable: false, render: (el) => <span>{el}</span>},
    { label: "PRIORITY", filter: "priority", filterable: false, render: (el) => <span>{el}</span> },
  ];
  

  const table = tickets?.data?.data && tickets?.data?.data?.length ? (
    <div className="report-table-container-flex">
      <div className="report-table-container scrollbar-blue">
        <CommonTableComponent
          headers={headers}
          datas={tickets?.data?.data}
          firstElementSticky={true}
          searchOption={false}
        />
     </div>
    </div>
  ) : null;

  const pagingation = table ? (
    <div className="report-table-container-flex">
      <div className="pagination-container">
            <TablePagination
                startPage={START_PAGE}
                currentPage={currentPage}
                totalPages={totalPages}
                setCurrentPage={setCurrentPage}
                itemsPerPage={itemsPerPage}
                toNextPage={toNextPage}
                toLastPage={toLastPage}
                toPreviousPage={toPreviousPage}
                toFirstPage={toFirstPage}
                exportButton={false}
            />
      </div>
    </div>
  ) : null;

  
  return (
    <div className="container-bg-design common-div-container">
    <div style={{ display: "flex", flexDirection: "column", width: "100%", alignItems: "center" }}>
       <h1 style={{ marginTop: "6vh" }} className="bebas-neue-regular gta-b">IBE Support ticket </h1>

       {/* input form */}
       <TicketForm partials={partials.data}/>

       {table}
       {pagingation}
      
    </div>
    </div>
  );
};

export default MainSupport;
