import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { NavLink } from "react-router-dom";
import CurrencyConverter from "../../Currency/CustomCurrencyConverter";
import { ApiHook } from "../../hooks/apiHook";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import { useQueryClient } from "@tanstack/react-query";
import Loader from "react-js-loader";
import { colors } from "@mui/material";

const UpgradeProducts = ({
    setActiveStep,
    products,
    cartData,
    setCartData,
    setTotalAmount,
    setTotalPv,
    totalAmount,
    totalPv,
    userCountry,
    conversionFactor,
}) => {
    const { t } = useTranslation();
    const [cartProducts, setCartProducts] = useState(products);

    useEffect(() => {
        setCartProducts(products);
    }, [products]);

    // const conversionFactor = useSelector(
    //     (state) => state?.user?.conversionFactor
    // );
    // const items = ApiHook.CallCartItems();

    const handleQuantityChange = (product, type) => {
        // const [value, defaultValue] = type === "plus" ? [1, 1] : [-1, 0];
        console.log(type, "111111");
        const { id: packageId, price, mrp, upfsv } = product;
        if (type === "plus") {
            setCartData((cart) => ({
                ...cart,
                [packageId]: {
                    ...product,
                    qty: cart[packageId] ? cart[packageId]?.qty + 1 : 1,
                },
            }));
            setTotalAmount((total) => total + Number(mrp));
            setTotalPv((total) => total + Number(upfsv));
        } else if (
            type === "minus" &&
            cartData[packageId] &&
            cartData[packageId]?.qty > 0
        ) {
            setCartData((cart) => ({
                ...cart,
                [packageId]: {
                    ...product,
                    qty: cart[packageId] ? cart[packageId]?.qty - 1 : 0,
                },
            }));
            let [cumulativeTotalAmount, cumulativeTotalPv] = [
                totalAmount - Number(mrp),
                totalPv - Number(upfsv),
            ];
            setTotalAmount(cumulativeTotalAmount);
            setTotalPv(cumulativeTotalPv);
        }
    };
    const gotoCart = () => {
        // if (totalPv < fsvCriteria) {
        //     toast.error(`Please add more products more.`);
        // } else {
        // handleFormValueChange(cartData, "cartData");
        // handleFormValueChange(totalAmount, "totalAmount");
        // handleFormValueChange(totalPv, "totalPv");
        setActiveStep(2);
        // }
    };

    const colorsList = [
        "#E4F0F2",
        "#E5F1DC",
        "#F9F4EF",
        "#d9f9dd",
        "#f8f0dd",
        "#def8fe",
    ];
    return (
        <div className="register-cart-container">
            <div className="common-div-header gta-b">
                IBE UPGRADE SIGNUP SHOPPING CART
            </div>
            <div style={{}}>
                <div className="shoping_page_section">
                    <div className="row">
                        {cartProducts?.length ? (
                            cartProducts?.map((product, index) => (
                                <div
                                    className="col-xl-4 col-lg-4 col-md-4"
                                    key={index}
                                >
                                    <div className="product_box_container">
                                        <div
                                            className="product_box"
                                            style={{
                                                backgroundColor:
                                                    colorsList[index % 6],
                                            }}
                                        >
                                            <div className="product_box_product">
                                                {" "}
                                                <NavLink
                                                // to={`/product-details/${product.id}`}
                                                >
                                                    <div className="product_box_image">
                                                        <img
                                                            src={
                                                                product.image ??
                                                                "/images/product3.jpg"
                                                            }
                                                            alt="product"
                                                        />
                                                    </div>
                                                </NavLink>
                                                <div className="product_box_content">
                                                    <div className="product_box_head">
                                                        {product.name}
                                                    </div>
                                                    <div className="product_box_category">
                                                        {product.category}
                                                    </div>
                                                    {/* <div className="product_box_amnt">{`${
                                                userSelectedCurrency.symbolLeft
                                            } ${CurrencyConverter(
                                                product.price,
                                                conversionFactor
                                            )}`}</div> */}
                                                </div>
                                            </div>
                                        </div>
                                        <div className="product_box_btn_sec">
                                            <div className="product_box_btn1">
                                                <div className="product_box_amnt">{`${
                                                    conversionFactor.symbolLeft
                                                } ${CurrencyConverter(
                                                    product.price,
                                                    conversionFactor?.value
                                                )}`}</div>
                                            </div>
                                            <div className="product_box_btn2 row">
                                                <div className="col-3 product_box_btn2_text">
                                                    <button
                                                        className="product_box_btn2_button"
                                                        onClick={(e) => {
                                                            // e.preventDefault();
                                                            handleQuantityChange(
                                                                product,
                                                                "minus"
                                                            );
                                                        }}
                                                    >
                                                        -
                                                    </button>
                                                </div>
                                                <div className="col-3 product_box_btn2_text">
                                                    {cartData[product.id]
                                                        ?.qty ?? 0}
                                                </div>
                                                <div className="col-3 product_box_btn2_text">
                                                    <button
                                                        className="product_box_btn2_button"
                                                        onClick={(e) => {
                                                            // e.preventDefault();
                                                            handleQuantityChange(
                                                                product,
                                                                "plus"
                                                            );
                                                        }}
                                                    >
                                                        +
                                                    </button>
                                                </div>
                                                <button
                                                    className="col-3 product_box_btn2_cart"
                                                    onClick={gotoCart}
                                                >
                                                    <i
                                                        className="fa-solid fa-cart-shopping"
                                                        style={{
                                                            color: "#fff",
                                                        }}
                                                    ></i>
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ))
                        ) : (
                            // placeholder image
                            <div className="newsMainBg">
                                <img src="images/news-no-data.png" alt="" />
                                <div>{t("sorry_no_data_found")}</div>
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default UpgradeProducts;
