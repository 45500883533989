import React, { useState } from "react";
import "react-loading-skeleton/dist/skeleton.css";
import TreeViewNavbar from "../../components/Tree/TreeViewNavbar";
import GenealogyTreeContent from "../../components/Tree/genealogy-tree/GenealogyTreeContent";
import { ApiHook } from "../../hooks/apiHook";
import { Form } from "react-bootstrap";

const GenealogyTree = () => {
    const [selectedUserId, setSelectedUserId] = useState("");
    const [searchUsername, setSearchUsername] = useState("");
    const [doubleUser, setDoubleUser] = useState("");
    const [showSearchLoader, setShowSearchLoader] = useState(false);

    const [username, setUsername] = useState("");

    const handleChange = (e) => {
        const { value } = e.target;
        setUsername(value);
    };
    const handleSearch = () => {
        setSearchUsername(username);
    };
    const handleSearchButton = (username) => {
        setSearchUsername(username);
    };
    const handleKeyPress = (e) => {
        if (e.key === "Enter") {
            // If Enter key is pressed, trigger the search
            handleSearch();
        }
    };
    // api
    const genealogyTree = ApiHook.CallGenealogyTreeList("", "", searchUsername);
    return (
        <>
            {/* <TreeViewNavbar
        menu={"genealogyTree"}
        searchUsername={searchUsername}
        setSearchUsername={setSearchUsername}
        setSelectedUserId={setSelectedUserId}
        setShowSearchLoader={setShowSearchLoader}
      /> */}
            <div className="genoalogy-container">
                <div className="genealogy-search-container-flex">
                    <div className="genealogy-search-container">
                        <div className="genealogy-search-head">
                            DUAL TEAM GRAPHICAL GENEALOGY
                        </div>
                        <div className="search-options row">
                            <div className="col-lg-4 col-md-4 col-sm-12 ">
                                <button
                                    onClick={() => {
                                        setUsername(
                                            genealogyTree?.data?.leftMost ?? ""
                                        );
                                        handleSearchButton(
                                            genealogyTree?.data?.leftMost ?? ""
                                        );
                                    }}
                                >
                                    {" "}
                                    LEFT EXTREME
                                </button>
                            </div>
                            <div className="search-options-input-container col-lg-4 col-md-4 col-sm-12">
                                {username === "" && (
                                    <i className="fa-solid fa-magnifying-glass"></i>
                                )}
                                <input
                                    id="Search"
                                    type="text"
                                    onChange={(e) => handleChange(e)}
                                    onKeyPress={(e) => handleKeyPress(e)}
                                    value={username}
                                    onBlur={() => {
                                        if (username !== "") {
                                            handleSearch();
                                        }
                                    }}
                                />
                            </div>
                            <div className="col-lg-4 col-md-4 col-sm-12">
                                <button
                                    onClick={() => {
                                        setUsername(
                                            genealogyTree?.data?.rightMost ?? ""
                                        );
                                        handleSearchButton(
                                            genealogyTree?.data?.rightMost ?? ""
                                        );
                                    }}
                                >
                                    {" "}
                                    RIGHT EXTREME
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
                <GenealogyTreeContent
                    selectedUserId={selectedUserId}
                    setSelectedUserId={setSelectedUserId}
                    doubleUser={doubleUser}
                    setDoubleUser={setDoubleUser}
                    searchUsername={searchUsername}
                    setSearchUsername={setSearchUsername}
                    setInputUsername={setUsername}
                    isLoading={
                        genealogyTree.isRefetching ||
                        genealogyTree.status === "loading"
                    }
                />
            </div>
        </>
    );
};

export default GenealogyTree;
