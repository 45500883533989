import { useMutation, useQuery } from "@tanstack/react-query";

import API from "../../api/api";
import { BASE_URL, DEFAULT_KEY } from "../../config/config";
import axios from "axios";

const callApi = async (endpoint) => {
    try {
        const response = await API.get(endpoint);
        if (response.status === 200) {
            return response?.data?.data;
        } else {
            return response?.data?.data;
        }
    } catch (error) {
        console.log(error);
        throw error;
    }
};

export const AgentEwalletRequestSubmit = async (formData) => {
    const customAxios = axios.create({
        baseURL: BASE_URL,
    });

    customAxios.defaults.headers.common["api-key"] = DEFAULT_KEY;
    customAxios.defaults.headers.common["access-token"] =
        localStorage.getItem("access-token") || "";

    customAxios.defaults.headers.common["Content-Type"] = "multipart/form-data";

    try {
        const response = await customAxios.post(`agent-request-form`, formData);
        return response.data;
    } catch (error) {
        return error?.response?.data;
    }
};

export const CallGetAgentWalletRequestHistory = async (page) => {
    const response = await callApi(`agent-request-history?page=${page}`);
    return response;
};
export const CallGetAgentWalletTransferHistory = async (page) => {
    const response = await callApi(`agent-transfer-history?page=${page}`);
    return response;
};
export const CallGetAgentWalletRequestForm = async () => {
    const response = await callApi(`agent-request-form`);
    return response;
};

export const CallGetAgentWalletTransfer = async () => {
    const response = await callApi(`agent-trasnfer-details`);
    return response;
};

export const CallGetUserDataOfIbe = async (searchIbeNo) => {
    const response = await callApi(`find-user?searchIbe=${searchIbeNo}`);
    return response;
};

export const PostAgentUserTransfer = async (body) => {
    let response = await API.post("agent-wallet-transfer", JSON.stringify(body))
        .then((response) => response)
        .catch((error) => Promise.reject(error));
    console.log(response, "********sadc");
    return response;
};
