import React from "react";
import { Bar, Doughnut, Line } from "react-chartjs-2";
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
    ArcElement,
    PointElement,
    LineElement,
} from "chart.js";

ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
    ArcElement,
    PointElement,
    LineElement
);

export function BarChart({ datas, labels }) {
    const barChartData = {
        labels: labels,
        datasets: [
            {
                label: "LTSV",
                data: datas.ltsv,
                backgroundColor: "#E4F0F2",
                borderRadius: 10,
            },
            {
                label: "RTSV",
                data: datas.rtsv,
                backgroundColor: "#C8CFB7",
                borderRadius: 10,
            },
        ],
    };

    const options = {
        responsive: true,
        plugins: {
            legend: {
                // position: 'top',
                display: false,
            },
            // title: {
            //     display: true,
            //     text: 'LTSV/RTSV',
            // },
        },
        scales: {
            x: {
                grid: {
                    display: false,
                },
                border: {
                    display: false, // Keep the x-axis line visible
                },
            },
            y: {
                grid: {
                    display: false,
                },
                border: {
                    display: false, // Keep the x-axis line visible
                },
                beginAtZero: true,
                ticks: {
                    callback: function (value) {
                        return `$ ${value / 1000}k`;
                    },
                },
            },
        },
        // scales: {
        //     x: {
        //         stacked: true,
        //     },
        //     y: {
        //         stacked: true,
        //     }
        // }
    };
    return <Bar data={barChartData} options={options} />;
}

export function BarCommissionChart({ labels, datas }) {
    const barCommissionData = {
        labels: labels,
        datasets: [
            {
                label: "RTSV",
                data: datas,
                backgroundColor: "#C8CFB7",
                borderRadius: 10,
            },
        ],
    };

const barCommissionOptions = {
        responsive: true,
        plugins: {
            legend: {
                display: false,
            },
        },
        scales: {
            x: {
                grid: {
                    display: false,
                },
                border: {
                    display: false,
                },
            },
            y: {
                grid: {
                    display: false,
                },
                border: {
                    display: false,
                },
                beginAtZero: true,
                ticks: {
                    callback: function (value) {
                        return `$ ${value / 1000}k`;
                    },
                },
            },
        },
    };
    return <Bar data={barCommissionData} className="dognut_custom_height" options={barCommissionOptions} />;
}

export function DoughnutChart({ data, labels }) {
    const doughnutOptions = {
        responsive: true,
        maintainAspectRatio: true,
        cutout: '56%',
        plugins: {
            legend: {
                display: false,
            },
        },
    };
    const doughnutData = {
        labels: labels,
        datasets: [
            {
                data: data,
                backgroundColor: ["#904B21", "#C8CFB7", "#749842", "#2E8CA5"],
                hoverBackgroundColor: [
                    "#904B21",
                    "#C8CFB7",
                    "#749842",
                    "#2E8CA5",
                ],
                borderColor: [
                    '#f1f1f1'
                ],
                borderWidth: 3, // Custom border width for the outline
                borderAlign: 'inner',
                hoverOffset: 4
            },
        ],
        
    };
    return (
        <Doughnut
            data={doughnutData}
            width={"100%"}
            height={"280"}
            options={doughnutOptions}
            className="dognut_custom_height"
        />
    );
}

export const LineChart = ({ data, labels }) => {
    const lineChartData = {
        labels: labels,
        datasets: [
            {
                label: "Rank",
                data: data,
                fill: false,
                backgroundColor: "#C5D3CD",
                borderColor: "#2E8CA5",
                pointBackgroundColor: "#0081CF",
                pointBorderColor: "#fff",
                pointHoverBackgroundColor: "#fff",
                pointHoverBorderColor: "#0081CF",
            },
        ],
    };

    const lineOptions = {
        responsive: true,
        plugins: {
            legend: {
                display: false,
            },
        },
        scales: {
            x: {
                grid: {
                    display: false,
                },
                border: {
                    display: false,
                },
            },
            y: {
                grid: {
                    display: false,
                },

                border: {
                    display: false,
                },
                beginAtZero: true,
                ticks: {
                    callback: function (value) {
                        const labels = [
                            "A",
                            "B",
                            "C",
                            "D",
                            "E",
                            "F",
                            "G",
                            "H",
                            "I",
                            "J",
                        ];
                        return labels[value - 1] || ""; // Mapping numbers to corresponding letters
                    },
                    stepSize: 1, // Ensure that each step on the y-axis is represented by one unit
                },
                max: 10, // Set the maximum value if your dataset's highest value needs to match the last label
                min: 0, // Set the minimum value to start from 'A'
            },
        },
    };

    return <Line data={lineChartData} options={lineOptions}  />;
};
