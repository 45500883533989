import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import CommonTableComponent from "../../components/Common/CommonTableComponent";
import TablePagination from "../../components/Common/table/TablePagination";
import { ApiHook } from "../../hooks/apiHook";
const IbeRankReport = () => {
    const { t } = useTranslation();
    const headers = [
        { label: "IBE #", filter: "ibe", filterable: true },
        { label: "IBE NAME", filter: "ibe_name", filterable: true },
        { label: "ENTRY LEVEL", filter: "entry_level", filterable: true },
        { label: "PLACEMENT", filter: "placement", filterable: true },
        { label: "BINARY LEVEL", filter: "binary_level", filterable: true },
        { label: "SPONSOR IBE", filter: "sponsor_ibe", filterable: true },
        { label: "SPONSOR NAME", filter: "sponsor_name", filterable: true },
        { label: "RANK", filter: "rank", filterable: true },
        { label: "CITY", filter: "city", filterable: true },
        { label: "STATE", filter: "state", filterable: true },
        { label: "COUNTRY", filter: "country", filterable: true },
        { label: "ORD DATE", filter: "ord_date", filterable: true },
        { label: "ORD TYPE", filter: "ord_type", filterable: true },
        { label: "SV $", filter: "sv", filterable: true },
        { label: "LETSV", filter: "letsv", filterable: true },
        { label: "RITSV", filter: "ritsv", filterable: true },
        { label: "CFVL", filter: "cfvl", filterable: true },
        { label: "CFVR", filter: "cfvr", filterable: true },
        { label: "LFTSV", filter: "lftsv", filterable: true },
        { label: "RFTSV", filter: "RFTSV", filterable: true },
        { label: "QCFVL", filter: "qcfvl", filterable: true },
        { label: "QCFVR", filter: "qcfvr", filterable: true },
        { label: "SV TYPE", filter: "sv_type", filterable: true },
    ];

    const [apiCallStatus, setApiCallStatus] = useState(true);
    const [filters, setFilters] = useState({});

    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);

    // const ibeOrderReport = ApiHook.CallgetIbeOrderReport({
    //     filters: Object.entries(filters)?.reduce((prev, curr) => {
    //         console.log(curr);
    //         if (!curr[1]?.isEditting && curr[1]?.value !== "") {
    //             return { ...prev, [curr[0]]: curr[1]?.value };
    //         }
    //         return prev;
    //     }, {}),
    //     apiCallStatus,
    //     setApiCallStatus,
    //     setCurrentPage,
    //     setTotalPages,
    // });

    const datas = [
        {
            ibe: 1057368,
            ibe_name: "MOHAMMAD",
            entry_level: "Start UP",
            placement: "L",
            binary_level: "93",
            sponsor_ibe: 1057368,
            sponsor_name: "MUKESH KUMAR RAO",
            rank: "Consultant",
            city: "GHATAKPUKUR",
            state: "Haryana",
            country: "India",
            ord_date: "6/26/2024, 9:24 AM",
            ord_type: "First Order Pack",
            sv: 40.0,
            letsv: 40.0,
            ritsv: 40.0,
            cfvl: 40.0,
            cfvr: 40.0,
            lftsv: 40.0,
            RFTSV: 40.0,
            qcfvl: 40.0,
            qcfvr: 40.0,
            sv_type: "FSV",
        },
        {
            ibe: 1057368,
            ibe_name: "MOHAMMAD",
            entry_level: "Start UP",
            placement: "L",
            binary_level: "93",
            sponsor_ibe: 1057368,
            sponsor_name: "MUKESH KUMAR RAO",
            rank: "Consultant",
            city: "GHATAKPUKUR",
            state: "Haryana",
            country: "India",
            ord_date: "6/26/2024, 9:24 AM",
            ord_type: "First Order Pack",
            sv: 40.0,
            letsv: 40.0,
            ritsv: 40.0,
            cfvl: 40.0,
            cfvr: 40.0,
            lftsv: 40.0,
            RFTSV: 40.0,
            qcfvl: 40.0,
            qcfvr: 40.0,
            sv_type: "FSV",
        },
        {
            ibe: 1057368,
            ibe_name: "MOHAMMAD",
            entry_level: "Start UP",
            placement: "L",
            binary_level: "93",
            sponsor_ibe: 1057368,
            sponsor_name: "MUKESH KUMAR RAO",
            rank: "Consultant",
            city: "GHATAKPUKUR",
            state: "Haryana",
            country: "India",
            ord_date: "6/26/2024, 9:24 AM",
            ord_type: "First Order Pack",
            sv: 40.0,
            letsv: 40.0,
            ritsv: 40.0,
            cfvl: 40.0,
            cfvr: 40.0,
            lftsv: 40.0,
            RFTSV: 40.0,
            qcfvl: 40.0,
            qcfvr: 40.0,
            sv_type: "FSV",
        },
        {
            ibe: 1057368,
            ibe_name: "MOHAMMAD",
            entry_level: "Start UP",
            placement: "L",
            binary_level: "93",
            sponsor_ibe: 1057368,
            sponsor_name: "MUKESH KUMAR RAO",
            rank: "Consultant",
            city: "GHATAKPUKUR",
            state: "Haryana",
            country: "India",
            ord_date: "6/26/2024, 9:24 AM",
            ord_type: "First Order Pack",
            sv: 40.0,
            letsv: 40.0,
            ritsv: 40.0,
            cfvl: 40.0,
            cfvr: 40.0,
            lftsv: 40.0,
            RFTSV: 40.0,
            qcfvl: 40.0,
            qcfvr: 40.0,
            sv_type: "FSV",
        },
        {
            ibe: 1057368,
            ibe_name: "MOHAMMAD",
            entry_level: "Start UP",
            placement: "L",
            binary_level: "93",
            sponsor_ibe: 1057368,
            sponsor_name: "MUKESH KUMAR RAO",
            rank: "Consultant",
            city: "GHATAKPUKUR",
            state: "Haryana",
            country: "India",
            ord_date: "6/26/2024, 9:24 AM",
            ord_type: "First Order Pack",
            sv: 40.0,
            letsv: 40.0,
            ritsv: 40.0,
            cfvl: 40.0,
            cfvr: 40.0,
            lftsv: 40.0,
            RFTSV: 40.0,
            qcfvl: 40.0,
            qcfvr: 40.0,
            sv_type: "FSV",
        },
        {
            ibe: 1057368,
            ibe_name: "MOHAMMAD",
            entry_level: "Start UP",
            placement: "L",
            binary_level: "93",
            sponsor_ibe: 1057368,
            sponsor_name: "MUKESH KUMAR RAO",
            rank: "Consultant",
            city: "GHATAKPUKUR",
            state: "Haryana",
            country: "India",
            ord_date: "6/26/2024, 9:24 AM",
            ord_type: "First Order Pack",
            sv: 40.0,
            letsv: 40.0,
            ritsv: 40.0,
            cfvl: 40.0,
            cfvr: 40.0,
            lftsv: 40.0,
            RFTSV: 40.0,
            qcfvl: 40.0,
            qcfvr: 40.0,
            sv_type: "FSV",
        },
        {
            ibe: 1057368,
            ibe_name: "MOHAMMAD",
            entry_level: "Start UP",
            placement: "L",
            binary_level: "93",
            sponsor_ibe: 1057368,
            sponsor_name: "MUKESH KUMAR RAO",
            rank: "Consultant",
            city: "GHATAKPUKUR",
            state: "Haryana",
            country: "India",
            ord_date: "6/26/2024, 9:24 AM",
            ord_type: "First Order Pack",
            sv: 40.0,
            letsv: 40.0,
            ritsv: 40.0,
            cfvl: 40.0,
            cfvr: 40.0,
            lftsv: 40.0,
            RFTSV: 40.0,
            qcfvl: 40.0,
            qcfvr: 40.0,
            sv_type: "FSV",
        },
        {
            ibe: 1057368,
            ibe_name: "MOHAMMAD",
            entry_level: "Start UP",
            placement: "L",
            binary_level: "93",
            sponsor_ibe: 1057368,
            sponsor_name: "MUKESH KUMAR RAO",
            rank: "Consultant",
            city: "GHATAKPUKUR",
            state: "Haryana",
            country: "India",
            ord_date: "6/26/2024, 9:24 AM",
            ord_type: "First Order Pack",
            sv: 40.0,
            letsv: 40.0,
            ritsv: 40.0,
            cfvl: 40.0,
            cfvr: 40.0,
            lftsv: 40.0,
            RFTSV: 40.0,
            qcfvl: 40.0,
            qcfvr: 40.0,
            sv_type: "FSV",
        },
        {
            ibe: 1057368,
            ibe_name: "MOHAMMAD",
            entry_level: "Start UP",
            placement: "L",
            binary_level: "93",
            sponsor_ibe: 1057368,
            sponsor_name: "MUKESH KUMAR RAO",
            rank: "Consultant",
            city: "GHATAKPUKUR",
            state: "Haryana",
            country: "India",
            ord_date: "6/26/2024, 9:24 AM",
            ord_type: "First Order Pack",
            sv: 40.0,
            letsv: 40.0,
            ritsv: 40.0,
            cfvl: 40.0,
            cfvr: 40.0,
            lftsv: 40.0,
            RFTSV: 40.0,
            qcfvl: 40.0,
            qcfvr: 40.0,
            sv_type: "FSV",
        },
        {
            ibe: 1057368,
            ibe_name: "MOHAMMAD",
            entry_level: "Start UP",
            placement: "L",
            binary_level: "93",
            sponsor_ibe: 1057368,
            sponsor_name: "MUKESH KUMAR RAO",
            rank: "Consultant",
            city: "GHATAKPUKUR",
            state: "Haryana",
            country: "India",
            ord_date: "6/26/2024, 9:24 AM",
            ord_type: "First Order Pack",
            sv: 40.0,
            letsv: 40.0,
            ritsv: 40.0,
            cfvl: 40.0,
            cfvr: 40.0,
            lftsv: 40.0,
            RFTSV: 40.0,
            qcfvl: 40.0,
            qcfvr: 40.0,
            sv_type: "FSV",
        },
        {
            ibe: 1057368,
            ibe_name: "MOHAMMAD",
            entry_level: "Start UP",
            placement: "L",
            binary_level: "93",
            sponsor_ibe: 1057368,
            sponsor_name: "MUKESH KUMAR RAO",
            rank: "Consultant",
            city: "GHATAKPUKUR",
            state: "Haryana",
            country: "India",
            ord_date: "6/26/2024, 9:24 AM",
            ord_type: "First Order Pack",
            sv: 40.0,
            letsv: 40.0,
            ritsv: 40.0,
            cfvl: 40.0,
            cfvr: 40.0,
            lftsv: 40.0,
            RFTSV: 40.0,
            qcfvl: 40.0,
            qcfvr: 40.0,
            sv_type: "FSV",
        },
    ];
    const startPage = 1;
    const itemsPerPage = 25;
    const toNextPage = () => {
        // setIsFetchable(true);
        if (currentPage < totalPages) {
            setCurrentPage(currentPage + 1);
        }
    };

    const toLastPage = () => {
        setCurrentPage(totalPages);
    };

    const toPreviousPage = () => {
        if (currentPage > startPage) {
            setCurrentPage(currentPage - 1);
        }
    };

    const toFirstPage = () => {
        setCurrentPage(startPage);
    };

    const slider = document.getElementsByClassName("report-table-container")[0];
    if (slider) {
        let isDown = false;
        let startX;
        let scrollLeft;

        slider.addEventListener("mousedown", (e) => {
            isDown = true;
            slider.classList.add("active");
            startX = e.pageX - slider.offsetLeft;
            scrollLeft = slider.scrollLeft;
        });
        slider.addEventListener("mouseleave", () => {
            isDown = false;
            slider.classList.remove("active");
        });
        slider.addEventListener("mouseup", () => {
            isDown = false;
            slider.classList.remove("active");
        });
        slider.addEventListener("mousemove", (e) => {
            if (!isDown) return;
            e.preventDefault();
            const x = e.pageX - slider.offsetLeft;
            const walk = (x - startX) * 3; //scroll-fast
            slider.scrollLeft = scrollLeft - walk;
            console.log(walk);
        });
    }
    return (
        <>
            <div className="report-Container">
                <div className="report-header"> IBE RANK REPORT</div>
                <div className="report-table-container-flex">
                    <div
                        className="report-table-container scrollbar-blue"
                        // style={{ display: "flex", flexDirection: "column" }}
                    >
                        <CommonTableComponent
                            headers={headers}
                            datas={datas}
                            filters={filters}
                            firstElementSticky={true}
                            searchOption={true}
                            setFilters={setFilters}
                            onSearch={() => {
                                setApiCallStatus(true);
                            }}
                        />
                    </div>
                </div>
                <div className="report-table-container-flex">
                    <div className="pagination-container">
                        {datas && datas?.length !== 0 && (
                            <TablePagination
                                startPage={startPage}
                                currentPage={currentPage}
                                totalPages={totalPages}
                                setCurrentPage={setCurrentPage}
                                itemsPerPage={itemsPerPage}
                                toNextPage={toNextPage}
                                toLastPage={toLastPage}
                                toPreviousPage={toPreviousPage}
                                toFirstPage={toFirstPage}
                                exportButton={true}
                            />
                        )}
                    </div>
                </div>
            </div>
        </>
    );
};

export default IbeRankReport;
