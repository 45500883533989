import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import CommonTableComponent from "../../components/Common/CommonTableComponent";
import TablePagination from "../../components/Common/table/TablePagination";
import { ApiHook } from "../../hooks/apiHook";
import { data } from "jquery";
import CommonInput from "../../components/Common/CommonInputComponent";
import CustomFileInput from "../../components/Common/CustomFileInput";
import { useForm } from "react-hook-form";
import {
    isANumber,
    isAlphaOnly,
    isAlphanumeric,
    isMaxLength,
} from "../../utils/validationHealpers";
import { GetTripForm } from "../../services/trip/trip";
const AgentWalletRequest = () => {
    const { t } = useTranslation();
    const headers = [
        { label: "IBE #", filter: "ibe", filterable: false },
        { label: "IBE NAME", filter: "ibeName", filterable: false },
        { label: "CITY", filter: "city", filterable: false },
        { label: "COUNTRY", filter: "country", filterable: false },
        { label: "TXN DATE", filter: "txnDate", filterable: false },
        {
            label: "UPLOADED FILES",
            filter: "uploadedFiles",
            filterable: false,
            render: (el) => (
                <span>
                    <a
                        href={el}
                        download
                        target="_blank"
                        style={{
                            color: "black",
                            textDecoration: "none",
                        }}
                    >
                        TXN Slip <i class="fa-solid fa-download"></i>
                    </a>
                </span>
            ),
        },
        { label: "BANK NAME", filter: "bankName", filterable: false },
        { label: "DEPOSIT MODE", filter: "depositMode", filterable: false },
        { label: "AMT ৳", filter: "amountBdt", filterable: false },
        {
            label: "STATUS",
            filter: "status",
            filterable: false,
            render: (el) => (
                <span
                    className="custom-table-cell"
                    style={
                        el !== "Approved"
                            ? { backgroundColor: "#fce5d2", color: "#985a35" }
                            : {}
                    }
                >
                    {el}
                </span>
            ),
        },
    ];
    const backgroundColor = "#e5f0f2";

    const [apiCallStatus, setApiCallStatus] = useState(true);
    const [filters, setFilters] = useState({});
    const [files, setFiles] = useState(new Map());

    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);

    const {
        register,
        setValue,
        trigger,
        watch,
        setError,
        getValues,
        reset,
        formState: { errors },
    } = useForm();
    const formValues = watch({});

    const handleFormValueChange = (value, type, options = {}) => {
        setValue(`${type}`, value, options);
        setError(`${type}`, { message: "" });
    };

    const agentRequestForm = ApiHook.CallGetAgentEwalletForm();
    const agentRequestData = ApiHook.CallgetAgentEwalletRequestReport({
        apiCallStatus,
        setApiCallStatus,
        setCurrentPage,
        setTotalPages,
        currentPage,
    });
    const agentRequestPost = ApiHook.CallPostAgentEwalletForm({
        setApiCallStatus,
    });

    const startPage = 1;
    const itemsPerPage = 25;

    const toNextPage = () => {
        // setIsFetchable(true);
        if (currentPage < totalPages) {
            setCurrentPage(currentPage + 1);
        }
    };

    const toLastPage = () => {
        setCurrentPage(totalPages);
    };

    const toPreviousPage = () => {
        if (currentPage > startPage) {
            setCurrentPage(currentPage - 1);
        }
    };

    const toFirstPage = () => {
        setCurrentPage(startPage);
    };

    const slider = document.getElementsByClassName("report-table-container")[0];
    if (slider) {
        let isDown = false;
        let startX;
        let scrollLeft;

        slider.addEventListener("mousedown", (e) => {
            isDown = true;
            slider.classList.add("active");
            startX = e.pageX - slider.offsetLeft;
            scrollLeft = slider.scrollLeft;
        });
        slider.addEventListener("mouseleave", () => {
            isDown = false;
            slider.classList.remove("active");
        });
        slider.addEventListener("mouseup", () => {
            isDown = false;
            slider.classList.remove("active");
        });
        slider.addEventListener("mousemove", (e) => {
            if (!isDown) return;
            e.preventDefault();
            const x = e.pageX - slider.offsetLeft;
            const walk = (x - startX) * 3; //scroll-fast
            slider.scrollLeft = scrollLeft - walk;
            console.log(walk);
        });
    }

    const handleSubmit = async () => {
        const isValid = await trigger();
        const txnCopyFile = files?.get("txnSlip");
        if (!files.size || !txnCopyFile.length) {
            setError("txnSlip", { message: "TXN SLIP is required" });
            return;
        }
        if (isValid) {
            const formData = new FormData();
            formData.append(`file`, txnCopyFile[0], txnCopyFile[0]?.name);
            formData.append("username", agentRequestForm?.data?.data?.username);
            // formValues?.map((ite))
            Object.entries(formValues)?.map((item) => {
                formData.append(item[0], item[1]);
            });
            agentRequestPost.mutate(formData, {
                onSuccess: (res) => {
                    console.log("///////////////zkjxnvcdskjf");
                    if (res.status) {
                        setFiles(new Map());
                        setValue("amountBdt", "");
                        setValue("bankName", "");
                        setValue("city", "");
                        setValue("country", "");
                        setValue("depositMode", "");
                        setValue("txnDate", "");
                    }
                },
            });
        }
    };
    console.log(agentRequestForm, "=====");
    const countryList = (data) => {
        return data.map((item) => item?.name);
    };
    return (
        <>
            <div className="container-bg-design common-div-container">
                <div className="common-div-header gta-b">
                    IBE E-WALLET CREDIT REQUEST FORM
                </div>
                <div className="credit-request-form-container">
                    <div className="credit-request-form">
                        <CommonInput
                            label="IBE #"
                            placeholder={"0000000000"}
                            backgroundColor={backgroundColor}
                            value={agentRequestForm?.data?.data?.ibeNo}
                            disabled={true}
                        />
                        <CommonInput
                            label="NAME"
                            placeholder={"Ibe Name"}
                            backgroundColor={backgroundColor}
                            value={agentRequestForm?.data?.data?.username}
                            disabled={true}
                        />
                        <CommonInput
                            label="CITY"
                            placeholder={"Your City"}
                            backgroundColor={backgroundColor}
                            formRegister={register("city", {
                                required: "City is required",
                            })}
                            onChange={({ target: { value } }) => {
                                if (
                                    isAlphaOnly(value) &&
                                    isMaxLength(value, 20)
                                ) {
                                    handleFormValueChange(value, "city");
                                }
                            }}
                            isValidationEnabled={true}
                        />
                        {errors?.city?.message !== "" && (
                            <span className="error-message-validator">
                                {errors?.city?.message}
                            </span>
                        )}
                        <CommonInput
                            label="country"
                            placeholder={"Select Country"}
                            type="select"
                            backgroundColor={backgroundColor}
                            formRegister={register("country", {
                                required: "Country is required",
                            })}
                            onChange={(e) => {
                                console.log(e.target.value);
                                handleFormValueChange(
                                    e.target.value,
                                    "country"
                                );
                            }}
                            options={countryList(
                                agentRequestForm?.data?.data?.countries ?? [""]
                            )}
                        />
                        {errors?.country?.message !== "" && (
                            <span className="error-message-validator">
                                {errors?.country?.message}
                            </span>
                        )}
                        <CommonInput
                            label="TXN DATE"
                            backgroundColor={backgroundColor}
                            type="date"
                            value={formValues?.txnDate}
                            isValidationEnabled={true}
                            formRegister={register("txnDate", {
                                required: "Transaction Date is required",
                            })}
                            onChange={(e) => {
                                handleFormValueChange(
                                    e.target.value,
                                    "txnDate"
                                );
                            }}
                        />
                        {errors?.txnDate?.message !== "" && (
                            <span className="error-message-validator">
                                {errors?.txnDate?.message}
                            </span>
                        )}
                        <div style={{ marginBottom: "15px" }}>
                            <label className="custom-label">
                                UPLOAD TXN SLIP COPY
                            </label>
                            <CustomFileInput
                                id={"txnSlip"}
                                label="UPLOAD TXN SLIP COPY"
                                backgroundColor={backgroundColor}
                                files={files}
                                setFiles={setFiles}
                            />
                            <div style={{ marginTop: "10px" }}>
                                <span className="credit-request-form-file-span">
                                    *Supported Formats: Jpeg, Pdf, Png / Maximum
                                    upload file size: 4MB
                                </span>
                            </div>
                        </div>
                        {errors?.txnSlip?.message !== "" && (
                            <span className="error-message-validator">
                                {errors?.txnSlip?.message}
                            </span>
                        )}
                        <CommonInput
                            label="Bank Name"
                            backgroundColor={backgroundColor}
                            onChange={(e) => {
                                if (
                                    isAlphaOnly(e.target.value) &&
                                    isMaxLength(e.target.value, 30)
                                ) {
                                    handleFormValueChange(
                                        e.target.value,
                                        "bankName"
                                    );
                                }
                            }}
                            isValidationEnabled={true}
                            formRegister={register("bankName", {
                                required: "Bank Name is required",
                                minLength: {
                                    value: 3,
                                    message:
                                        "Pan Number should be greater than 3",
                                },
                            })}
                        />
                        {errors?.bankName?.message !== "" && (
                            <span className="error-message-validator">
                                {errors?.bankName?.message}
                            </span>
                        )}

                        <CommonInput
                            label="Deposit Mode"
                            type="select"
                            backgroundColor={backgroundColor}
                            value={formValues?.depositMode}
                            formRegister={register("depositMode", {
                                required: "Deposit Mode is required",
                            })}
                            onChange={(e) => {
                                handleFormValueChange(
                                    e.target.value,
                                    "depositMode"
                                );
                            }}
                            options={["Cash", "Cheque"]}
                        />
                        {errors?.depositMode?.message !== "" && (
                            <span className="error-message-validator">
                                {errors?.depositMode?.message}
                            </span>
                        )}

                        <CommonInput
                            label="AMOUNT (TAKA)"
                            placeholder={0}
                            backgroundColor={backgroundColor}
                            formRegister={register("amountBdt", {
                                required: "amountBdt is required",
                            })}
                            value={formValues?.amountBdt}
                            onChange={(e) => {
                                if (isANumber(e.target.value)) {
                                    handleFormValueChange(
                                        e.target.value,
                                        "amountBdt"
                                    );
                                }
                            }}
                            isValidationEnabled={true}
                        />
                        {errors?.amountBdt?.message !== "" && (
                            <span className="error-message-validator">
                                {errors?.amountBdt?.message}
                            </span>
                        )}
                        <button
                            className="common-button"
                            onClick={handleSubmit}
                        >
                            SUBMIT
                        </button>
                    </div>
                </div>
                {/* <div className="report-Container"> */}
                <div>
                    <div className="report-table-container-flex">
                        <div
                            className="report-table-container scrollbar-blue"
                            // style={{ display: "flex", flexDirection: "column" }}
                        >
                            <CommonTableComponent
                                headers={headers}
                                datas={agentRequestData?.data?.data}
                                // datas={datas}
                                filters={filters}
                                firstElementSticky={true}
                                searchOption={false}
                                setFilters={setFilters}
                                onSearch={() => {
                                    setApiCallStatus(true);
                                }}
                            />
                        </div>
                    </div>
                    <div className="report-table-container-flex">
                        <div className="pagination-container">
                            {agentRequestData?.data?.data &&
                                agentRequestData?.data?.data?.length !== 0 && (
                                    <TablePagination
                                        startPage={startPage}
                                        currentPage={currentPage}
                                        totalPages={totalPages}
                                        setCurrentPage={setCurrentPage}
                                        itemsPerPage={itemsPerPage}
                                        toNextPage={toNextPage}
                                        toLastPage={toLastPage}
                                        toPreviousPage={toPreviousPage}
                                        toFirstPage={toFirstPage}
                                        exportButton={true}
                                    />
                                )}
                        </div>
                    </div>
                </div>
                {/* </div> */}
            </div>
        </>
    );
};

export default AgentWalletRequest;
