const CustomCurrencyConverter = (amount, conversionFactor = 1, rev = 0) => {
    const numberDisplay = (num) => {
        if (isNaN(num)) return "0";
        if (num === 0) return "0";

        let number = Math.abs(num);
        if (number < 1e4) return number.toFixed(2);
        if (number < 1e6) return (number / 1e3).toFixed(2) + "k";
        if (number < 1e9) return (number / 1e6).toFixed(2) + "m";
        if (number >= 1e9) return (number / 1e9).toFixed(2) + "B";
    };

    return numberDisplay(Number(amount) * Number(conversionFactor));
};

export default CustomCurrencyConverter;
