import React, { useState } from "react";
import CommonInput from "../Common/CommonInputComponent";
import { PhoneInput } from "react-international-phone";
import { isValidPhoneNumber } from "libphonenumber-js";
import CustomCurrencyConverter from "../../Currency/CustomCurrencyConverter";
import { ApiHook } from "../../hooks/apiHook";
import { useTranslation } from "react-i18next";

const CheckoutPayment = ({
    register,
    setValue,
    trigger,
    watch,
    setError,
    getValues,
    errors,
    formValues,
    handleFormValueChange,
    setActiveStep,
    walletUsername,
    totalAmount,
    conversionFactor,
    handleSubmit,
}) => {
    const { t } = useTranslation();
    const backgroundColor = "#fff";
    const [transPass, setTransPass] = useState("");
    const [transPassCheck, setTransPassCheck] = useState(false);
    const [transPassResposne, setTransPassResposne] = useState({
        success: null,
        error: null,
    });
    const [submitButtonActive, setSubmitButtonActive] = useState(true);

    const transPassCheckData = ApiHook.CallTransPasswordCheck(
        transPass,
        transPassCheck,
        setTransPassCheck,
        setSubmitButtonActive,
        formValues.totalAmount,
        transPassResposne,
        setTransPassResposne,
        "e-wallet"
    );
    return (
        <>
            <div className="">
                <div
                    style={{
                        display: "flex",
                        justifyContent: "center",
                    }}
                >
                    <div className="register-verification-container">
                        <CommonInput
                            type="text"
                            label="Total Amount"
                            backgroundColor={backgroundColor}
                            value={`${
                                conversionFactor?.symbolLeft
                            } ${CustomCurrencyConverter(
                                totalAmount,
                                conversionFactor?.value
                            )}`}
                            defaultValue=""
                            disabled={true}
                        />
                        <CommonInput
                            type="text"
                            label="IBE #"
                            backgroundColor={backgroundColor}
                            value={walletUsername}
                            defaultValue=""
                            disabled={true}
                        />
                        <CommonInput
                            type="password"
                            label="Transaction Password"
                            placeholder={"Your Transaction password"}
                            backgroundColor={backgroundColor}
                            value={formValues?.transactionPassword}
                            onChange={(e) => {
                                handleFormValueChange(
                                    e.target.value,
                                    "transactionPassword"
                                );
                                setTransPass(e.target.value);
                            }}
                            defaultValue=""
                        />
                        {transPassResposne?.error && (
                            <span className="error-message-validator">
                                {t(transPassResposne?.error)}
                            </span>
                        )}

                        <button
                            className="common-button"
                            onClick={(e) => {
                                setTransPassCheck(true);
                                handleFormValueChange(2, "paymentType", {
                                    shouldValidate: true,
                                    shouldDirty: true,
                                });
                            }}
                        >
                            CHECK
                        </button>
                        {transPassCheckData?.data?.status && (
                            <button
                                className="common-button"
                                onClick={(e) => {
                                    handleSubmit();
                                }}
                            >
                                SUBMIT
                            </button>
                        )}
                    </div>
                </div>
            </div>
        </>
    );
};

export default CheckoutPayment;
