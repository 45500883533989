import React, { useState } from "react";
import { NavLink, useNavigate, useParams } from "react-router-dom";
import CheckoutPackage from "./Checkout";
import CheckoutAddress from "../../components/shopping/CheckoutAddress";
import CheckoutOrderSummary from "../../components/shopping/CheckoutOrderSummary";
import CheckoutPayment from "./CheckoutPayment";
import anime from "animejs/lib/anime.es.js";
import { ApiHook } from "../../hooks/apiHook";
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";

const IbeCheckoutLayout = ({
    register,
    setValue,
    trigger,
    watch,
    setError,
    getValues,
    errors,
    formValues,
    handleFormValueChange,
    setActiveStepPr,
    products,
    activeCurrencies,
    fsvCriteria,
    cartData,
    setCartData,
    setTotalAmount,
    setTotalPv,
    totalAmount,
    totalPv,
    registerFields,
}) => {
    const [activeStep, setActiveStep] = useState(1);
    // const [cartData, setCartData] = useState(formValues?.cartData);
    // const [totalAmount, setTotalAmount] = useState(formValues?.totalAmount);
    // const [totalPv, setTotalPv] = useState(formValues?.totalPv);

    // ----------------------------------- API -------------------------------------
    // const registerMutation = ApiHook.CallRegisterUser();
    const replicaRegisterMutation = ApiHook.CallReplicaRegisterPost();

    const conversionFactor =
        (formValues?.country).toLowerCase() === "india"
            ? {
                  symbolLeft: activeCurrencies["INR"]?.symbol,
                  value: activeCurrencies["INR"]?.value,
              }
            : {
                  symbolLeft: activeCurrencies["BDT"]?.symbol,
                  value: activeCurrencies["BDT"]?.value,
              };
    const handleNext = async () => {
        if ((formValues?.country).toLowerCase() === "india") {
            handleSubmit();
        } else {
            const nextStep = activeStep + 1;
            animateStepTransition(nextStep);
            setActiveStep(nextStep);
        }
    };
    const handleSubmit = async () => {
        replicaRegisterMutation.mutate(
            {
                ...formValues,
                cartData,
                totalAmount,
                totalPv,
                referralId: registerFields?.sponsorData?.id,
            },
            {
                onSuccess: (res) => {
                    console.log(res, "====================================");
                },
            }
        );
    };

    const handleBack = () => {
        const prevStep = activeStep - 1;
        animateStepTransition(prevStep);
        setActiveStep(prevStep);
    };

    const animateStepTransition = () => {
        const containerElement = document.querySelector("#animation");
        // Set initial position of the next step
        anime.set(containerElement, { opacity: 0 });
        // Animate the next step in
        anime({
            targets: containerElement,
            opacity: [0, 1],
            duration: 1100,
            easing: "easeInOutQuad",
        });
    };
    return (
        <>
            <div className="common-div-header gta-b">
                {activeStep === 1 ? "CART CHECKOUT" : "CART PAYMENT"}
            </div>
            <div className="checkout_contant_area_section">
                <div className="checkout_contant_cart_sec">
                    <div id="animation">
                        {activeStep === 1 && (
                            <CheckoutPackage
                                totalAmount={totalAmount}
                                handleNext={handleNext}
                                handleFormValueChange={handleFormValueChange}
                                setActiveStepPr={setActiveStepPr}
                                products={products}
                                setCartData={setCartData}
                                totalPv={totalPv}
                                setTotalAmount={setTotalAmount}
                                setTotalPv={setTotalPv}
                                conversionFactor={conversionFactor}
                                cartData={cartData}
                                fsvCriteria={fsvCriteria}
                                setValue={setValue}
                            />
                        )}
                        {activeStep === 2 && (
                            <CheckoutPayment
                                register={register}
                                setValue={setValue}
                                trigger={trigger}
                                watch={watch}
                                setError={setError}
                                getValues={getValues}
                                errors={errors}
                                formValues={formValues}
                                handleFormValueChange={handleFormValueChange}
                                setActiveStep={setActiveStep}
                                registerFields={registerFields}
                                totalAmount={totalAmount}
                                conversionFactor={conversionFactor}
                                handleSubmit={handleSubmit}
                            />
                        )}
                    </div>
                </div>
            </div>
        </>
    );
};

export default IbeCheckoutLayout;
