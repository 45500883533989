import React, { useRef } from "react";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useParams } from "react-router";
import { formatDate } from "../../utils/formateDate";
import CurrencyConverter from "../../Currency/CurrencyConverter";
import { ApiHook } from "../../hooks/apiHook";
import { useReactToPrint } from "react-to-print";

const RegisterComplete = () => {
  const { t } = useTranslation();
  const tableRef = useRef();
  const conversionFactor = useSelector(
    (state) => state?.user
  );
  const userSelectedCurrency = useSelector(
    (state) => state?.user?.selectedCurrency
  );
  // console.log(conversionFactor);
  const selectedLanguage = useSelector(
    (state) => state?.user?.selectedLanguage
  );
  const [successMessageShow, setSuccessMessageShow] = useState(true);
  const params = useParams();
  const username = params?.username;
  const preview = ApiHook.CallLetterPreview(username);
  const user = preview?.data?.userData;
  const order = preview?.data?.orderData;
  const companyData = preview?.data?.companyData;
  const packageData = preview?.data?.productData;
  const welcomeContent = preview?.data?.welcomeLetter;
  const selectedLanguageId = selectedLanguage?.id;
  const selectedWelcomeContent = welcomeContent?.find(
    (item) => item.id === selectedLanguageId
  );
  const welcomeContentHtml = selectedWelcomeContent?.content || "";
  const date = preview?.data?.date;
  const closeAlert = () => {
    setSuccessMessageShow(false);
  };
  const companyProfile = useSelector(
    (state) => state?.dashboard?.appLayout?.companyProfile
  );
  // Use useEffect to automatically call closeAlert after 3 seconds
  useEffect(() => {
    const timer = setTimeout(() => {
      closeAlert();
    }, 3000);

    // Clear the timer when the component unmounts or when successMessageShow changes to false
    return () => {
      clearTimeout(timer);
    };
  }, [successMessageShow]);

  const handlePrint = useReactToPrint({
    content: () => tableRef.current,
  });

  return (
    // <div className="container-bg-design">
    //  <div
    //       className="alertNotification"
    //       style={{
    //         width: "100%",
    //         border: "1px solid rgb(153 88 53)",
    //         background: "rgba(153, 88, 53, 0.4)",
    //         display: "inline-block",
    //         color: "rgb(153 88 53)",
    //         padding: "8px 15px 8px 15px",
    //         // borderRadius: "20px",
    //         display: "flex",
    //         alignItems: "center",
    //         justifyContent: "space-between",
    //         flexWrap: "wrap",
    //       }}
    //     >
    //       <p style={{ margin: "0" }}>{t("regCompleted")}</p>
    //       {/* <div className="closeBtnIcon" onClick={closeAlert}>
    //                     <i className="fa-solid fa-xmark"></i>
    //                 </div> */}
    //     </div>
    //   <div className="ewallet_table_section" ref={tableRef}>
    //     <div className="ewallet_table_section_cnt">
    //       <div
    //         className="table-responsive min-hieght-table"
    //         style={{ width: "auto" }}
    //       >
    //         <div
    //           className="cmpnyAddressname"
    //           style={{
    //             textAlign: "end",
    //             padding: "10px",
    //             display: "flex",
    //             justifyContent: "space-between",
    //           }}
    //         >
    //           {/* <div>
    //             <img
    //               src={companyData?.logo ?? "/images/logo_user.png"}
    //               alt=""
    //             />
    //           </div> */}
    //           {/* <div>
    //             <p style={{ marginBottom: "5px" }}>{companyData?.name}</p>
    //             <p style={{ marginBottom: "0" }}>{companyData?.address}</p>
    //           </div> */}
    //         </div>
    //         {/* <div style={{ display: "flex", justifyContent: "space-between" }}>
    //           <div>
    //             <img src={companyProfile?.logo ?? "/images/logo_user.png"} alt="" />
    //           </div>
    //           <div >
    //             <p className="font-weight-bold mb-1">
    //               {`${companyProfile?.name}`}
    //             </p>
    //             <p className="font-weight-bold mb-1">
    //               {`${companyProfile?.address}`}
    //             </p>
    //             <p className="text-muted">
    //               {`${t("date")}: ${new Date().toLocaleDateString('en-GB')}`}
    //             </p>
    //           </div>
    //         </div> */}
    // <table className="striped">
    //   <tbody>
    //     <tr className="font-weight-bold mb-1">
    //       <td >{t("invoice")}</td>
    //       <td >{order?.invoiceNo}</td>
    //     </tr>
    //     <tr className="font-weight-bold mb-1">
    //       <td >{t("username")}</td>
    //       <td >{user?.username}</td>
    //     </tr>
    //     <tr className="font-weight-bold mb-1">
    //       <td >{t("fullName")}</td>
    //       <td >{user?.fullName}</td>
    //     </tr>
    //     <tr className="font-weight-bold mb-1">
    //       <td >{t("sponsor")}</td>
    //       <td >{user?.sponsorName}</td>
    //     </tr>
    //     {/* <tr className="font-weight-bold mb-1">
    //       <td >{t("regFee")}</td>
    //       <td >
    //         {userSelectedCurrency?.symbolLeft}{" "}
    //         {CurrencyConverter(preview?.data?.regFee, conversionFactor)}
    //       </td>
    //     </tr> */}
    //     {/* {packageData && (
    //       <> */}
    //     <tr className="font-weight-bold mb-1">
    //       <td >{t("package")}</td>
    //       <td >{user?.package}</td>
    //     </tr>
    //     <tr className="font-weight-bold mb-1">
    //       <td >{t("packageAmount")}</td>
    //       <td >
    //         {userSelectedCurrency?.symbolLeft}{" "}
    //         {CurrencyConverter(user?.price, conversionFactor)}
    //       </td>
    //     </tr>
    //     {/* </>
    //     )} */}
    //     <tr className="font-weight-bold mb-1">
    //       <td >{t("totalAmount")}</td>
    //       {console.log(
    //         order?.totalAmount,
    //         CurrencyConverter(
    //           Number(order?.totalAmount),
    //           conversionFactor
    //         )
    //       )}
    //       <td >
    //         {userSelectedCurrency?.symbolLeft}{" "}
    //         {CurrencyConverter(order?.totalAmount, conversionFactor)}
    //       </td>
    //     </tr>
    //   </tbody>
    // </table>
    //       </div>
    //       {/* <div className="container-fluid">
    //         <div
    //           className="regardssec"
    //           style={{
    //             display: "inline-block",
    //             width: "100%",
    //             marginTop: "10px",
    //           }}
    //         >
    //           <div
    //             className="regardBg"
    //             style={{
    //               backgroundColor: "#e9f4f9",
    //               borderRadius: "5px",
    //               padding: "15px",
    //               width: "100%",
    //               height: "auto",
    //             }}
    //           >
    //             <p style={{ marginBottom: "5px" }}>
    //               <div
    //                 dangerouslySetInnerHTML={{
    //                   __html: welcomeContentHtml,
    //                 }}
    //               />
    //             </p>
    //             <p>{t("wishingRegards")}</p>
    //             <p>{t("admin")}</p>
    //             <p>{companyData?.name}</p>
    //             <p style={{ marginBottom: "0" }}>{t("date")}</p>
    //             <p>{formatDate(date)}</p>
    //           </div>
    //         </div>
    //       </div> */}
    //     </div>
    //   </div>
    // </div>
    <div style={{ height: "100vh" }}>
      {/* <div className="page_head_top">{t("purchase_invoice")}</div> */}
      <div className="container-bg-design" style={{ height: "100vh" }}>
        <div className="col-md-8 m-auto">
          <div className="purchase_invoice" ref={tableRef}>
            <div
              className="alertNotification"
              style={{
                width: "100%",
                border: "1px solid rgb(153 88 53)",
                background: "rgba(153, 88, 53, 0.4)",
                display: "inline-block",
                color: "rgb(153 88 53)",
                padding: "8px 15px 8px 15px",
                // borderRadius: "20px",
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                flexWrap: "wrap",
              }}
            >
              <p style={{ margin: "0" }}>{t("regCompleted")}</p>
            </div>
            <h3 style={{ margin: "20px" }}>Registration Details</h3>
            <table className="striped">
              <thead
                style={{ backgroundColor: "rgb(153 88 53)", color: "white" }}
              >
                {" "}
                <tr>
                  <th className="text-uppercase  font-weight-bold" style={{textAlign:"center"}}></th>{" "}
                  <th className="text-uppercase  font-weight-bold"></th>{" "}
                </tr>
              </thead>
              <tbody>
                <tr className="font-weight-bold mb-1">
                  <td>{t("invoice")}</td>
                  <td>{order?.invoiceNo}</td>
                </tr>
                <tr className="font-weight-bold mb-1">
                  <td>{t("username")}</td>
                  <td>{user?.username}</td>
                </tr>
                <tr className="font-weight-bold mb-1">
                  <td>{t("fullName")}</td>
                  <td>{user?.fullName}</td>
                </tr>
                <tr className="font-weight-bold mb-1">
                  <td>{t("sponsor")}</td>
                  <td>{user?.sponsorName}</td>
                </tr>
                <tr className="font-weight-bold mb-1">
                  <td>{t("package")}</td>
                  <td>{user?.package}</td>
                </tr>
                <tr className="font-weight-bold mb-1">
                  <td>{t("packageAmount")}</td>
                  <td>
                    {userSelectedCurrency?.symbolLeft}{" "}
                    {CurrencyConverter(user?.price, conversionFactor)}
                  </td>
                </tr>

                <tr className="font-weight-bold mb-1">
                  <td>{t("totalAmount")}</td>
                  {console.log(
                    order?.totalAmount,
                    CurrencyConverter(
                      Number(order?.totalAmount),
                      conversionFactor
                    )
                  )}
                  <td>
                    {userSelectedCurrency?.symbolLeft}{" "}
                    {CurrencyConverter(order?.totalAmount, conversionFactor)}
                  </td>
                </tr>
              </tbody>
            </table>
            {/* <hr className="my-1" /> */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default RegisterComplete;
