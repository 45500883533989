import React, { useState } from "react";
import CommonInput from "../Common/CommonInputComponent";
import { PhoneInput } from "react-international-phone";
import { isValidPhoneNumber } from "libphonenumber-js";

const IbeCountrySelection = ({
    formValues,
    registerFields,
    handleFormValueChange,
    setActiveStep,
}) => {
    const backgroundColor = "#e5f0f2";
    const countryList = (data) => {
        return data.map((item) => item?.name);
    };
    return (
        <>
            <div className="">
                <div className="common-div-header gta-b">COUNTRY</div>

                <div
                    style={{
                        display: "flex",
                        justifyContent: "center",
                    }}
                >
                    <div className="register-verification-container">
                        <CommonInput
                            type="select"
                            label="Country"
                            backgroundColor={backgroundColor}
                            value={formValues?.country}
                            onChange={(e) => {
                                console.log(e.target.value);
                                handleFormValueChange(
                                    e.target.value,
                                    "country"
                                );
                                handleFormValueChange("", "state");
                            }}
                            options={countryList(
                                registerFields?.contactInformation?.contactField?.filter(
                                    (item) => item?.code === "country"
                                )[0]?.options ?? [""]
                            )}
                            defaultValue=""
                        />

                        <button
                            className="common-button"
                            onClick={(e) => {
                                if (formValues?.country) {
                                    if (
                                        (formValues?.country).toLowerCase() ===
                                        "india"
                                    ) {
                                        handleFormValueChange(
                                            "91",
                                            "phoneCode"
                                        );
                                        setActiveStep(2);
                                    } else {
                                        handleFormValueChange(
                                            "880",
                                            "phoneCode"
                                        );
                                        setActiveStep(3);
                                    }
                                }
                            }}
                        >
                            SUBMIT
                        </button>
                    </div>
                </div>
            </div>
        </>
    );
};

export default IbeCountrySelection;
