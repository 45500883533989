import CancelIcon from "@mui/icons-material/Cancel";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";




const CustomFileInput = ({ id, files, setFiles, maxFiles = 2 }) => {
    const { t } = useTranslation();

    const handleImageSelection = (e) => {
        const { id, files: selectedFiles } = e.target;
    
        setFiles((prevFiles) => {
          const updatedFiles = new Map(prevFiles);
          const existingFiles = updatedFiles.get(id) || [];
          if (existingFiles.length === maxFiles) {
            toast.error(t("Maximum two files allowed!"));
            return prevFiles;
          }
    
          updatedFiles.set(id, [...existingFiles, ...Array.from(selectedFiles)]);
          return updatedFiles;
        });
    };
    
    const handleRemoveFile = (key, file) => {
        const filesAfterRemovedFile = files.get(key)?.filter((el) => el.name !== file.name);

        setFiles((prevFiles) => {
          const updatedFiles = new Map(prevFiles);
    
          updatedFiles.set(key, filesAfterRemovedFile);
          return updatedFiles;
        });
    };

    return <div style={{ position: "relative", width: "100%" }} className="square-border-cut">
      <input
        id={id}
        type="file"
        className="kyc-file-input"
        onChange={handleImageSelection}
      />
      <div className="kyc-file-parent">
        <label htmlFor={id} className="kyc-file-label">
          Choose File
        </label>
        <div className="kyc-file-list">
          {files.get(id + "")?.length ? (
            files.get(id + "")?.map((file, index) => (
              <div className="kyc-file-preview" key={index}>
                <span style={{ fontSize: "12px" }}>
                  {file.name.slice(0, 15)}
                </span>
                <CancelIcon
                  onClick={() => handleRemoveFile(id + "", file)}
                  className="kyc-file-remove-icon"
                />
              </div>
            ))
          ) : (
            <span className="kyc-no-file">No files choosen</span>
          )}
        </div>
      </div>
    </div>
};


export default CustomFileInput;