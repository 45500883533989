import React from "react";
import { ApiHook } from "../../hooks/apiHook";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import CurrencyConverter from "../../Currency/CurrencyConverter";
import { useQueryClient } from "@tanstack/react-query";
import { useNavigate, useParams } from "react-router";

const CheckoutPackage = ({
    items,
    totalAmount,
    handleNext,
    shippingCharge = 0,
    conversionFactor,
}) => {
    const params = useParams();
    console.log(items);
    const { t } = useTranslation();
    const queryClient = useQueryClient();
    const navigate = useNavigate();

    const addCartMutation = ApiHook.CallAddToCart();
    const decrementCartMutation = ApiHook.CallDecrementCartItem();
    const removeCartMutation = ApiHook.CallRemoveCartItem();

    // if (items?.length === 0) {
    //     navigate("/shopping");
    // }

    const handleQuantityChange = (event, packageId) => {
        if (event.target.id === "plus") {
            addCartMutation.mutate(
                { packageId: packageId },
                {
                    onSuccess: (res) => {
                        if (res.status) {
                            queryClient.invalidateQueries({
                                queryKey: ["cart-items"],
                            });
                        }
                    },
                }
            );
        } else if (event.target.id === "minus") {
            decrementCartMutation.mutate(
                { packageId: packageId },
                {
                    onSuccess: (res) => {
                        if (res.status) {
                            queryClient.invalidateQueries({
                                queryKey: ["cart-items"],
                            });
                        } else {
                            queryClient.invalidateQueries({
                                queryKey: ["cart-items"],
                            });
                        }
                    },
                }
            );
        }
    };

    const handleRemoveCartItem = (packageId) => {
        removeCartMutation.mutate(
            { packageId: packageId },
            {
                onSuccess: (res) => {
                    if (res.status) {
                        queryClient.invalidateQueries({
                            queryKey: ["cart-items"],
                        });
                        if (items?.data?.length === 0) {
                            navigate("/shopping");
                        }
                    }
                },
            }
        );
    };

    return (
        <>
            <div className="cart-checkout">
                <div className="cart-checkout-table">
                    <div className="cart-table">
                        <div className="cart-table-header">
                            <div className="cart-table-header-cell cell-flex-3 ">
                                PRODUCTS
                            </div>
                            <div className="cart-table-header-cell ">QTY</div>
                            <div className="cart-table-header-cell ">PRICE</div>
                            <div className="cart-table-header-cell ">AMT</div>
                            <div className="cart-table-header-cell ">
                                REMOVE
                            </div>
                        </div>
                        {items?.length > 0 &&
                            items?.map((item) => (
                                <div className="cart-table-body">
                                    {/* <div className="cart-table-body-cell">
                                        {item?.code}
                                    </div> */}
                                    <div className="cart-table-body-cell cell-flex-3 ">
                                        <div style={{ display: "flex" }}>
                                            <img
                                                src={item?.image}
                                                alt=""
                                                style={{
                                                    width: "100px",
                                                    height: "100px",
                                                    borderRadius: "10px",
                                                    marginRight: "20px",
                                                }}
                                            />
                                            <div
                                                style={{
                                                    textAlign: "left",
                                                    display: "flex",
                                                    flexDirection: "column",
                                                    justifyContent: "center",
                                                }}
                                            >
                                                <article
                                                    className="product_name_article"
                                                >
                                                    {item?.name}
                                                </article>
                                                <article>
                                                    Item Code: {item?.code}
                                                </article>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="cart-table-body-cell">
                                        {`${
                                            conversionFactor.symbolLeft
                                        } ${CurrencyConverter(
                                            item.price,
                                            conversionFactor?.value
                                        )}`}
                                    </div>
                                    <div className="cart-table-body-cell">
                                        <div className="cart-cell-qty-up">
                                            <button
                                                id="minus"
                                                onClick={(e) => {
                                                    handleQuantityChange(
                                                        e,
                                                        item?.packageId
                                                    );
                                                }}
                                            >
                                                -
                                            </button>
                                            {item?.quantity}
                                            <button
                                                id="plus"
                                                onClick={(e) => {
                                                    handleQuantityChange(
                                                        e,
                                                        item?.packageId
                                                    );
                                                }}
                                            >
                                                +
                                            </button>
                                        </div>
                                    </div>
                                    <div className="cart-table-body-cell">
                                        {`${
                                            conversionFactor.symbolLeft
                                        } ${CurrencyConverter(
                                            item.price * item.quantity,
                                            conversionFactor?.value
                                        )}`}
                                    </div>
                                    <div
                                        className="cart-table-body-cell"
                                        style={{ alignItems: "center" }}
                                    >
                                        <button
                                            className="cart-table-remove-btn"
                                            onClick={() => {
                                                handleRemoveCartItem(
                                                    item?.packageId
                                                );
                                            }}
                                        >
                                            <i
                                                className="fa-solid fa-xmark"
                                                style={{ color: "#8c5331" }}
                                            ></i>
                                        </button>
                                    </div>
                                </div>
                            ))}
                    </div>
                </div>
                {/* <div className="row cart-checkout-total-container">
                    <div className="col-6 cart-checkout-total-left">
                        <button
                            id="addMoreBtn"
                            onClick={() => {
                                navigate("/shopping");
                            }}
                        >
                            ADD MORE
                        </button>
                    </div>
                    <div className="col-6 cart-checkout-total-right">
                        <div className="cart-checkout-total-right-header">
                            CART TOTALS
                        </div>
                        <div className="cart-checkout-total-right-total">
                            <article className="row">
                                <section className="col-7 cart-checkout-total-right-total-text">
                                    
                                <div>SUB TOTAL</div>
                                                                <div></div>
                                </section>
                                <section className="col-5  cart-checkout-total-right-total-amount">
                                    {`${
                                        userSelectedCurrency.symbolLeft
                                    } ${CurrencyConverter(
                                        Number(totalAmount),
                                        conversionFactor
                                    )}`}
                                </section>
                            </article>
                            <article className="row">
                                <section className="col-7 cart-checkout-total-right-total-text">
                                    SHIPPING
                                </section>
                                <section className="col-5  cart-checkout-total-right-total-amount">
                                    {`${
                                        userSelectedCurrency.symbolLeft
                                    } ${CurrencyConverter(
                                        Number(shippingCharge),
                                        conversionFactor
                                    )}`}
                                </section>
                            </article>
                            <article className="row">
                                <section className="col-7 cart-checkout-total-right-total-text">
                                    TOTAL
                                </section>
                                <section className="col-5  cart-checkout-total-right-total-amount">
                                    {`${
                                        userSelectedCurrency.symbolLeft
                                    } ${CurrencyConverter(
                                        Number(totalAmount) +
                                            Number(shippingCharge),
                                        conversionFactor
                                    )}`}
                                </section>
                            </article>
                        </div>
                        <button onClick={handleNext}>PROCEED CHECKOUT</button>
                    </div>
                </div> */}
                <div className="cart-checkout-total-container">
                    <div className="cart-checkout-total-section">
                        <div>SUB TOTAL</div>
                        <div>
                            {" "}
                            {`${
                                conversionFactor.symbolLeft
                            } ${CurrencyConverter(
                                Number(totalAmount),
                                conversionFactor?.value
                            )}`}
                        </div>
                    </div>
                    <div className="cart-checkout-total-section">
                        <div>SHIPPING</div>
                        <div>
                            {" "}
                            {`${
                                conversionFactor.symbolLeft
                            } ${CurrencyConverter(
                                Number(shippingCharge),
                                conversionFactor?.value
                            )}`}
                        </div>
                    </div>
                    <div className="cart-checkout-total-section">
                        <div>TOTAL</div>
                        <div>
                            {" "}
                            {`${
                                conversionFactor.symbolLeft
                            } ${CurrencyConverter(
                                Number(totalAmount) + Number(shippingCharge),
                                conversionFactor?.value
                            )}`}
                        </div>
                    </div>
                </div>

                <div className="cart-checkout-button-container">
                    <button
                        id="addMoreBtn"
                        onClick={() => {
                            navigate(`/shopping/${params?.type}`);
                        }}
                        className="addMoreBtn"
                    >
                        CONTINUE SHOPPING
                    </button>
                    <button onClick={handleNext}>PROCEED CHECKOUT</button>
                </div>
            </div>
        </>
    );
};

export default CheckoutPackage;
