import React, { useState } from "react";
import CommonInput from "../Common/CommonInputComponent";
import { ApiHook } from "../../hooks/apiHook";
import { useQueryClient } from "@tanstack/react-query";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import SubmitButton from "../Common/buttons/SubmitButton";
import { PersonalDetailsFormFields } from "../../utils/profileData";

const PersonalDetails = ({ profileDetails, countries }) => {
    const { t } = useTranslation();
    const queryClient = useQueryClient();
    const [editInput, setEditInput] = useState(false);
    const [formValues, setFormValues] = useState({
        name: profileDetails.name ?? "",
        sponsorName: profileDetails.sponserName ?? "",
        placement: profileDetails.placement ?? "",
        fatherName: profileDetails.fatherName ?? "",
        firstName: profileDetails.firstName ?? "",
        secondName: profileDetails.secondName ?? "",
        gender: profileDetails.gender ?? "",
        dob: profileDetails.dob ?? "",
        age: profileDetails.age ?? "",
        maritalStatus: profileDetails.maritalStatus ?? "",
        whatsappNumber: profileDetails.waNumber ?? "",
        mobileNumber: profileDetails.mobile ?? "",
        email: profileDetails.email ?? "",
        panNo: profileDetails.panNo ?? "",
        education: profileDetails.education ?? "",
        occupation: profileDetails.occupation ?? "",
        address1: profileDetails.address1 ?? "",
        address2: profileDetails.address2 ?? "",
        country: profileDetails.country ?? "",
        city: profileDetails.city ?? "",
        state: profileDetails.state ?? "",
        pincode: profileDetails.pincode ?? "",
        phoneCode: profileDetails.phoneCode ?? "",
    });

    const updateMutation = ApiHook.CallUpdatePersonalDetails();

    const handleSubmit = async () => {
        updateMutation.mutate(formValues, {
            onSuccess: (res) => {
                if (res.status) {
                    // dispatch(
                    //     updateProfile({
                    //         profileDetails: formValues,
                    //     })
                    // );
                    queryClient.invalidateQueries({ queryKey: ["profile"] });
                    toast.success(t(res?.data));
                    editInput(false);
                }
            },
        });
    };

    const handleChange = (name, value) => {
        setFormValues((prevValues) => ({
            ...prevValues,
            [name]: value,
        }));
    };
    const countryList = (data) => {
        return data.map((item) => item?.name);
    };

    const stateList = (data) => {
        console.log(data, "99999999999");
        if (!formValues?.country) {
            return []; // Return an empty array if there's no country specified.
        }

        const selectedCountry = data.find(
            (country) => country.name === formValues.country
        );
        if (!selectedCountry) {
            return []; // Return an empty array if the selected country is not found.
        }

        return selectedCountry.States.map((state) => state?.name);
    };

    return (
        <>
            <div className="d-flex justify-content-between">
                <h2>PERSONAL DETAILS</h2>
                {/* <div className="editBg">
                    <button
                        style={{
                            textDecoration: "none",
                            cursor: "pointer",
                            borderRadius: "10px",
                            padding: "10px 15px",
                        }}
                        onClick={() => setEditInput(!editInput)}
                    >
                        <i
                            className="fa-solid fa-pen-to-square"
                            style={{ color: "#32009c" }}
                        ></i>
                    </button>
                </div> */}
            </div>
            <div className="profile_sec-inputs row">
                {PersonalDetailsFormFields.map((field, i) => {
                    console.log(field, "**************");
                    if (field?.name === "country") {
                        return (
                            <div key={i} className="col-md-6">
                                <CommonInput
                                    disabled={!editInput ?? false}
                                    type={field.type}
                                    options={countryList(countries) ?? []}
                                    label={field.label}
                                    placeholder={field.placeholder}
                                    backgroundColor={"#e5f0f2"}
                                    value={formValues[field.name] || ""}
                                    onChange={(e) =>
                                        handleChange(field.name, e.target.value)
                                    }
                                    phoneCode={formValues?.phoneCode}
                                />
                            </div>
                        );
                    } else if (field?.name === "state") {
                        return (
                            <div key={i} className="col-md-6">
                                <CommonInput
                                    disabled={!editInput ?? false}
                                    type={field.type}
                                    options={stateList(countries) ?? []}
                                    label={field.label}
                                    placeholder={field.placeholder}
                                    backgroundColor={"#e5f0f2"}
                                    value={formValues[field.name] || ""}
                                    onChange={(e) =>
                                        handleChange(field.name, e.target.value)
                                    }
                                />
                            </div>
                        );
                    } else {
                        return (
                            <div key={i} className="col-md-6">
                                <CommonInput
                                    disabled={!editInput ?? false}
                                    type={field.type}
                                    options={field.options}
                                    label={field.label}
                                    placeholder={field.placeholder}
                                    backgroundColor={"#e5f0f2"}
                                    value={formValues[field.name] || ""}
                                    onChange={(e) =>
                                        handleChange(field.name, e.target.value)
                                    }
                                    phoneCode={formValues?.phoneCode}
                                />
                            </div>
                        );
                    }
                })}
            </div>
            {/* <div className={`paymenytLinkBtn ${editInput ? "disabled" : ""}`}>
                <SubmitButton
                    className="btn"
                    isSubmitting={!editInput}
                    text={t("update")}
                    click={handleSubmit}
                />
            </div> */}
        </>
    );
};

export default PersonalDetails;
