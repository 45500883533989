// const BASE_URL = "http://192.168.9.7:5006/";
// const BASE_URL = "http://192.168.12.88:5006/";
const BASE_URL = "http://162.19.146.134:1698/";

const DEFAULT_KEY = "aswdcaswdcqwedrgbesdfbdfgberestgredsgsdfbdgvbqdxcdsnfgtyg";

const PAYPAL_CLIENT_ID =
    "Ad077-Gx8stGcfWQYawBiQUc4UbKWIRIg_PK4lHL4pzHR-uOPMz2vCk_jQ8QU9pcEw0j8U3UYDrEUS75";

export { BASE_URL, DEFAULT_KEY, PAYPAL_CLIENT_ID };
